import { Map } from 'immutable';
import { Input, Button, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

export function clearToken() {
  localStorage.clear()
}

export function getToken() {
  try {
    const authToken = localStorage.getItem('authToken');
    return new Map({ authToken });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export const sortNumber = (a, b, key) => {
  if (a[key] !== undefined && b[key] !== undefined && b[key] !== null && a[key] !== null) {
      if (a[key] < b[key])
          return -1;
      if (a[key] > b[key])
          return 1;
  }
  return 0;
}

export const sortFunction = (a,b,key) => {
  if (a[key] !== undefined && b[key] !== undefined && b[key] !== null && a[key] !== null) {
      if (a[key].toString().toLowerCase() < b[key].toString().toLowerCase())
      return -1;
      if (a[key].toString().toLowerCase() > b[key].toString().toLowerCase())
      return 1;
  }
  return 0;
}

export const sortDate = (a,b,key) => {
  a[key] = a[key].split('/').reverse().join('');
  b[key] = b[key].split('/').reverse().join('');
  return a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0;
}

/* 
    CHECK IF OBJECT IS EMPTY
*/
export const isEmpty = (obj) => {
  for(var key in obj) {
      if(obj.hasOwnProperty(key))
          return false;
  }
  return true;
}

export function convertToArray (obj) {
  let array = []
  for(var key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] && obj[key].length > 0)
          array.push({
              field   : key,
              keyword : obj[key][0]
          })
  }
  return array;
}

export const getColumnSearchProps = (dataIndex, handleSearch, handleReset, searchInput = "") => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<i className={"ion-search"} />
          }
          size="small"
          style={{ width: 90 }}
        >
          Search
            </Button>
        <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
          Reset
            </Button>
      </Space>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : '',
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => searchInput.select(), 100);
    }
  },
});

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = number => {
    return number > 1 ? 's' : '';
  };
  const number = num => (num > 9 ? '' + num : '0' + num);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + ' day' + numberEnding(days);
      } else {
        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return 'a few seconds ago';
  };
  return getTime();
}
