import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { getToken, clearToken } from '../../helpers/utility';
import actions from './actions';

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function*() {
      yield put({
        type: actions.LOGIN_SUCCESS,
        authToken: localStorage.getItem('authToken'),
        adminDetails: localStorage.getItem('adminDetails'),
        profile: 'Profile'
      }); 
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {
    yield localStorage.setItem('authToken', payload.authToken);
    yield localStorage.setItem('adminDetails', payload.adminDetails);
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    clearToken();
    yield put(push('/'));
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    const authToken = getToken().get('authToken');
    if (authToken) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        authToken,
        adminDetails: localStorage.getItem('adminDetails'),
        profile: 'Profile'
      });
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout)
  ]);
}
