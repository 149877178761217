const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (data) => {
    localStorage.setItem('isLoggedInCMS', true)    
    localStorage.setItem('authToken', data.authToken)
    localStorage.setItem('adminDetails', JSON.stringify(data.adminDetails))
    return  {
      type: actions.LOGIN_REQUEST
    }
  },
  logout: () => {
    localStorage.clear()
    return {
      type: actions.LOGOUT
    }
  }
};
export default actions;