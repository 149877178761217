const options = [
  {
    key: "",
    label: "sidebar.dashboard",
    leftIcon: "ion-speedometer",
  },
  {
    key: "admin",
    label: "sidebar.admin",
    leftIcon: "ion-person",
  },
  // {
  //   key: "user",
  //   label: "sidebar.user",
  //   leftIcon: "ion-ios-people",
  // },
  {
    key: "user",
    label: "sidebar.user",
    leftIcon: "ion-ios-people",
    children: [
      {
        key: "stable",
        label: "sidebar.stable",
        leftIcon: "ion-ios-people",
      },
      {
        key: "trainer",
        label: "sidebar.trainer",
        leftIcon: "ion-ios-people",
      },
      {
        key: "rider",
        label: "sidebar.rider",
        leftIcon: "ion-ios-people",
      },
    ],
  },
  {
    key: "withdrawal-request",
    label: "sidebar.withdrawalRequest",
    leftIcon: "ion-clipboard",
  },
  {
    key: "referrals",
    label: "sidebar.referral",
    leftIcon: "ion-ios-list-outline",
  },
  {
    key: "veterinaries",
    label: "sidebar.veterinaries",
    leftIcon: "ion-ios-pulse",
  },
  {
    key: "promotion",
    label: "sidebar.promotion",
    leftIcon: "ion-ios-list-outline",
    children: [
      {
        key: "promotion",
        label: "sidebar.promotion",
        leftIcon: "ion-ios-list",
      },
      {
        key: "promotion-request",
        label: "sidebar.promotionRequest",
        leftIcon: "ion-ios-list",
      },
      {
        key: "buy-sell-request",
        label: "sidebar.buySellRequest",
        leftIcon: "ion-ios-paper",
      },
    ],
  },

  // {
  //   key: "subscription",
  //   label: "sidebar.subscription",
  //   leftIcon: "ion-cash",
  //   children: [
  //     {
  //       key: "subscription",
  //       label: "sidebar.subscription",
  //       leftIcon: "ion-ios-list",
  //     },
  //     {
  //       key: "subscriptionTransactions",
  //       label: "sidebar.subscriptionTransactions",
  //       leftIcon: "ion-ios-list",
  //     },
  //   ],
  // },
  // {
  //   key: "faq",
  //   label: "sidebar.faq",
  //   leftIcon: "ion-help-circled",
  // },
  // {
  //   key: "static-page",
  //   label: "sidebar.staticPage",
  //   leftIcon: "ion-ios-paper",
  // },
  {
    key: "store",
    label: "sidebar.store",
    leftIcon: "ion-ios-list",
    children: [
      {
        key: "brand",
        label: "sidebar.brands",
        leftIcon: "ion-ios-list",
      },
      {
        key: "category",
        label: "sidebar.categories",
        leftIcon: "ion-ios-list",
      },
      {
        key: "sub-category",
        label: "sidebar.subCategories",
        leftIcon: "ion-ios-list",
      },
      {
        key: "vendor",
        label: "sidebar.vendor",
        leftIcon: "ion-ios-list",
      },
      // {
      //   key: "store-brand-inquiries",
      //   label: "sidebar.storeBrandInquiries",
      //   leftIcon: "ion-ios-list",
      // },
    ],
  },
  // {
  //   key: "coupon",
  //   label: "sidebar.coupon",
  //   leftIcon: "ion-ios-photos-outline",
  // },
  {
    key: "transaction",
    label: "sidebar.transaction",
    leftIcon: "ion-card",
  },
  {
    key: "issues",
    label: "sidebar.issues",
    leftIcon: "ion-alert",
  },
  // {
  //   key: "competitions",
  //   label: "sidebar.competitions",
  //   leftIcon: "ion-trophy",
  // },
  {
    key: "booking",
    label: "sidebar.booking",
    leftIcon: "ion-ios-book-outline",
  },
  {
    key: "simulator",
    label: "sidebar.simulator",
    leftIcon: "ion-android-calendar",
    children: [
      {
        key: "simulator/dashboard",
        label: "sidebar.simulator_dashboard",
        leftIcon: "",
      },
      {
        key: "simulator/bookings",
        label: "sidebar.simulator_booking_slots",
        leftIcon: "",
      },
      {
        key: "simulator/rider-bookings",
        label: "sidebar.simulator_bookings",
        leftIcon: "",
      },
      {
        key: "simulator/rider-bookings/calendar",
        label: "sidebar.simulator_bookings_timeline",
        leftIcon: ""
      }
    ],
  },
  {
    key: "notifications",
    label: "sidebar.notifications",
    leftIcon: "ion-android-notifications-none",
  },
  // {
  //   key: "stable-service",
  //   label: "sidebar.stableService",
  //   leftIcon: "ion-ios-list-outline",
  // },
  // {
  //   key: "rider-questions",
  //   label: "sidebar.riderQuestions",
  //   leftIcon: "ion-help",
  // },
  {
    key: "membership",
    label: "sidebar.membership",
    leftIcon: "ion-ribbon-b",
  },
  {
    key: "commission",
    label: "sidebar.commission",
    leftIcon: "ion-cash",
    children: [
      {
        key: "commission",
        label: "sidebar.StableCommission",
        leftIcon: "ion-cash",
      },
      {
        key: "freelancerCommission",
        label: "sidebar.freelancerCommissionPercentage",
        leftIcon: "ion-cash",
      },
      {
        key: "storeCommission",
        label: "sidebar.storeCommission",
        leftIcon: "ion-cash",
      },
    ],
  },
  {
    key: "settings",
    label: "sidebar.settings",
    leftIcon: "ion-ios-settings",
    children: [
      {
        key: "country",
        label: "sidebar.country",
        leftIcon: "ion-ios-list",
      },
      {
        key: "city",
        label: "sidebar.city",
        leftIcon: "ion-ios-list",
      },
      {
        key: "currencies",
        label: "sidebar.currency",
        leftIcon: "ion-ios-list-outline",
      },
      {
        key: "delivery-charges",
        label: "sidebar.deliveryCharges",
        leftIcon: "ion-ios-list-outline",
      },
      {
        key: "horse-breed",
        label: "sidebar.horseBreed",
        leftIcon: "ion-ios-list",
      },
      {
        key: "horse-color",
        label: "sidebar.horseColor",
        leftIcon: "ion-ios-list",
      },
      {
        key: "faq",
        label: "sidebar.faq",
        leftIcon: "ion-help-circled",
      },
      {
        key: "static-page",
        label: "sidebar.staticPage",
        leftIcon: "ion-ios-paper",
      },
      {
        key: "rider-questions",
        label: "sidebar.riderQuestions",
        leftIcon: "ion-help",
      },
      {
        key: "coupon",
        label: "sidebar.coupon",
        leftIcon: "ion-ios-photos-outline",
      },
      {
        key: "stable-service",
        label: "sidebar.stableService",
        leftIcon: "ion-ios-list-outline",
      },
    ],
  },
];
export default options;
