import React, { Component } from "react";
import { Route } from "react-router-dom";
import asyncComponent from "../../helpers/AsyncFunc";

const routes = [
  {
    path: "",
    component: asyncComponent(() => import("../Dashboard/Dashboard")),
  },
  {
    path: "admin",
    component: asyncComponent(() => import("../Admin/Admin")),
  },
  {
    path: "change-password",
    component: asyncComponent(() => import("../ChangePassword/ChangePassword")),
  },
  {
    path: "static-page",
    component: asyncComponent(() => import("../StaticPage/StaticPage")),
  },
  {
    path: "horse-breed",
    component: asyncComponent(() => import("../HorseBreed/HorseBreed")),
  },
  {
    path: "horse-color",
    component: asyncComponent(() => import("../HorseColor/HorseColor")),
  },
  {
    path: "imperial",
    component: asyncComponent(() => import("../Imperial/Imperial")),
  },
  {
    path: "city",
    component: asyncComponent(() => import("../City/City")),
  },
  {
    path: "country",
    component: asyncComponent(() => import("../Country/Country")),
  },
  {
    path: "delivery-charges",
    component: asyncComponent(() => import("../DeliveryCharge/DeliveryCharge")),
  },
  // {
  //   path       : 'user',
  //   component  : asyncComponent(() => import('../User/User'))
  // },
  {
    path: "trainer",
    component: asyncComponent(() => import("../User/User")),
  },
  {
    path: "stable",
    component: asyncComponent(() => import("../User/User")),
  },
  {
    path: "rider",
    component: asyncComponent(() => import("../User/User")),
  },
  {
    path: "withdrawal-request",
    component: asyncComponent(() => import("../WithdrawalRequest/WithdrawalRequest")),
  },
  {
    path: "user-information/:id",
    component: asyncComponent(() => import("../User/UserTabContainer")),
  },
  {
    path: "promotion",
    component: asyncComponent(() => import("../Promotion/Promotion")),
  },
  {
    path: "subscription",
    component: asyncComponent(() => import("../Subscription/Subscription")),
  },
  {
    path: "faq",
    component: asyncComponent(() => import("../Faq/Faq")),
  },
  {
    path: "brand",
    component: asyncComponent(() => import("../Store/Brand/Brand")),
  },
  {
    path: "category",
    component: asyncComponent(() => import("../Store/Category/Category")),
  },
  {
    path: "sub-category",
    component: asyncComponent(() => import("../Store/SubCategory/SubCategory")),
  },
  {
    path: "vendor",
    component: asyncComponent(() => import("../Store/Vendor/Vendor")),
  },
  {
    path: "vendor-information",
    component: asyncComponent(() => import("../Store/Vendor/VendorTabContainer")),
  },
  {
    path: "products",
    component: asyncComponent(() => import("../Store/Vendor/Products/Products")),
  },
  {
    path: "orders",
    component: asyncComponent(() => import("../Store/Vendor/Orders/Orders")),
  },
  {
    path: "subscriptionTransactions",
    component: asyncComponent(() => import("../SubscriptionPlan/SubscriptionPlanDetails")),
  },
  {
    path: "coupon",
    component: asyncComponent(() => import("../Coupon/Coupon")),
  },
  {
    path: "promotion-request",
    component: asyncComponent(() => import("../PromotionRequest/PromotionRequest")),
  },
  {
    path: "transaction",
    component: asyncComponent(() => import("../Transaction/Transaction")),
  },
  {
    path: "issues",
    component: asyncComponent(() => import("../Issues/Issues")),
  },
  {
    path: "competitions",
    component: asyncComponent(() => import("../Competitions/Competitions")),
  },
  {
    path: "competition-information/:id",
    component: asyncComponent(() => import("../Competitions/CompetitionDetails")),
  },
  {
    path: "booking",
    component: asyncComponent(() => import("../Booking/Booking")),
  },
  {
    path: "buy-sell-request",
    component: asyncComponent(() => import("../BuySellRequest/BuySellRequest")),
  },
  {
    path: "stable-service",
    component: asyncComponent(() => import("../StableService/StableService")),
  },
  {
    path: "rider-questions",
    component: asyncComponent(() => import("../RiderQuestions/RiderQuestions")),
  },
  {
    path: "membership",
    component: asyncComponent(() => import("../Membership/Membership")),
  },
  {
    path: "membership-details/:id",
    component: asyncComponent(() => import("../Membership/MembershipDetails")),
  },
  {
    path: "currencies",
    component: asyncComponent(() => import("../Currency/Currency")),
  },
  {
    path: "referrals",
    component: asyncComponent(() => import("../Referral/Referral")),
  },
  {
    path: "veterinaries",
    component: asyncComponent(() => import("../Veterinaries/Veterinaries")),
  },
  {
    path: "commission",
    component: asyncComponent(() => import("../Commission/Commission")),
  },
  {
    path: "freelancerCommission",
    component: asyncComponent(() => import("../FreelancerCommission/FreelancerCommission")),
  },
  {
    path: "storeCommission",
    component: asyncComponent(() => import("../Commission/Store/StoreCommission")),
  },
  {
    path: "simulator/bookings",
    component: asyncComponent(() => import("../Simulator/Bookings")),
  },
  {
    path: "simulator/dashboard",
    component: asyncComponent(() => import("../Simulator/Dashboard")),
  },
  {
    path: "simulator/rider-bookings",
    component: asyncComponent(() => import("../Simulator/RiderBookings")),
  },
  {
    path: "simulator/rider-bookings/calendar",
    component: asyncComponent(() => import("../Simulator/RiderBookingsCalendar")),
  },
  {
    path: "notifications",
    component: asyncComponent(() => import("../Notifications/NotificationList")),
  },
];

class AppRouter extends Component {
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        {routes.map((singleRoute) => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
      </div>
    );
  }
}

export default AppRouter;
