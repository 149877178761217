import React from 'react';
import { Link } from 'react-router-dom';
import { siteConfig } from '../../settings';
import Logo from "../../Assets/logo.png";

export default ({ collapsed }) => {
  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        <div>
          <h3>
            <Link to="/dashboard">
              <img src={Logo} />
            </Link>
          </h3>
        </div>
      ) : (
        <h3>
            <Link to="/dashboard">{siteConfig.siteName}</Link>
        </h3>
      )}
    </div>
  );
};
