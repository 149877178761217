import actions from './actions';

const initState = { authToken: null, isLoggedInCMS: false, adminDetails: null };

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        authToken: action.authToken,
        adminDetails: action.adminDetails,
        isLoggedInCMS: true
      };
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}